<template lang='pug'>
.page-wrap
	#properties
		router-link(:to="{path:'/admin/property/'+p.key}" :class="{live: p.numLive > 0}").property(v-for='p in admin.properties')
			p {{p.name}}
			p live connections: {{p.numLive}}
			p sessions today: {{p.sessions}}

</template>

<script>

import Live from '../mixins/Live'

export default{
	mixins: [Live],
	methods:{
		async getNumSessionsToday(){
			let today = new Date().toISOString();
			await fetch('/api/v1/sessions?pid=all&groupby=date&d1='+today, {credentials: 'include'})
			.then(response => response.json())
			.then(properties => {
			// map the session totals to the admin properties //
				this.admin.properties.forEach(p1 => {
					properties.forEach(p2 => {
					// p2.sessions[0].sessions = sessions today //
						let nToday = p2.sessions.length == 1 ? p2.sessions[0].sessions : 0;
						if (p1.key === p2.key) {
							p1.sessions = nToday;
						}
					});
				});
			// sort by sessions today descending //
				this.admin.properties.sort((a, b) => b.sessions - a.sessions);
			});
		},
		onLiveConnectionsChanged()
		{
			this.getNumSessionsToday();
		}
	},
	created(){
		this.init();
	}
}

</script>

<style lang="stylus" scoped>
h1
	margin-bottom 2vh
#properties
	width 94vw
	margin auto
	max-width 1200px
	display flex
	flex-wrap wrap
	padding-bottom 1%
	.property
		width 32%
		margin 1% 0 0 1%
		height 200px
		display flex
		flex-direction column
		align-items center
		justify-content center
		cursor pointer
		border 1px solid #ced4da
		border-radius 3px
		transition all .3s
		p
			margin 4px
	.property:hover
		color #333
		border 1px solid white
		background #eee
	.property.live
		border 0
		color white
		font-weight 500
		background #cf0000
	.property.live:hover
		background #ff5200
	@media (max-width: 1000px)
		.property
			width 48.5%
	@media (max-width: 600px)
		.property
			width 98%

</style>
