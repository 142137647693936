
export default {
	data(){
		return {
			admin: Object,
			property: {name:''},
			socket: Object,
			interval: Number
		}
	},
	beforeUnmount(){
		clearInterval(this.interval);
		this.socket.emit('close-admin-session');
	},
	methods:{
		init(onInitComplete){
			this.fetchAdmin(() => {
				this.initSocket(() => {
					if (onInitComplete) onInitComplete();
				});
			});
			this.interval = setInterval(this.calcDurationOfLiveSessions, 1000);
		},
		async fetchAdmin(done){
			const res = await fetch('/api/v1/admin', {credentials: 'include'})
			.then(response => response.json())
			.then(json => {
				this.admin = json;
			//	console.log('admin-data:', json);
				let pid = this.$route.params.key;
				if (pid) this.property = this.admin.properties.filter(p => p.key == pid)[0];
				done();
			});
		},
		initSocket(done){
			this.socket = io('/admins');
			this.socket.emit('init-admin-session');
			this.socket.on('live-connections', (properties) => {
			// map # of live connection to admin.properties array //
			//	console.log('live-connections', properties);
				this.admin.properties.forEach(p => {
					p.live = properties[p.key];
			// this has to be stored in a variable //
			// trying to read 'this.property.live.length' keeps throwing an error for some reason //
					p.numLive = properties[p.key].length;
				});
				this.calcDurationOfLiveSessions();
				if (this.onLiveConnectionsChanged) this.onLiveConnectionsChanged();
				done();
			});
		},
		calcDurationOfLiveSessions()
		{
			this.admin.properties.forEach(p => {
				p.live.forEach(session => {
					session.duration = (new Date() - new Date(session.date).getTime()) / 1000;
					if (session.pages[0] && session.pages[0].date){
						session.pages[0].duration = (new Date() - new Date(session.pages[0].date).getTime()) / 1000;
					}
				});
			});
		}
	}
}